<template>
    <el-dialog
            :title="$t('personalCenter.planExpiry')"
            :visible.sync="dialogVisible"
            width="900px"
    >
        <div class="html" style="min-height:60vh;max-height: 80vh;overflow-y: auto;text-align: left;  line-height: 2; ">
            <p :key="index" v-for="(item,index) in code">{{ $i18n.locale == 'zh' ? item.remark : item.dict_label }}</p>
        </div>
    </el-dialog>
</template>

<script>
    export default {
        name: "PurchaseNotesDialog",
        data() {
            return {
                code: [],
                dialogVisible: false
                // this.$i18n.locale == 'zh'
            };
        },
        methods: {
            open(code) {
                this.code = code;
                console.log(this.code)

                this.dialogVisible = true;
            },
            close() {
                this.dialogVisible = false;
            }
        }
    };
</script>

<style lang="scss" scoped>

    ::v-deep.el-dialog__wrapper .el-dialog__body {
        padding-top: 10px !important;
    }
</style>
