<template>
    <!--弹窗-->
    <el-dialog :visible.sync="dialogFormVisible" class="dialog-wrap" width="700px">
        <h1 class="dialog-title" slot="title">{{$t("bulletFrame.recommendOther")}}</h1>
        <div class="member_box auto">
            <div class="member_title">
                <span>*</span>
                {{$t("publish.phone")}}
            </div>
            <div style="display: inline-block;">
                <el-select filterable style="width:150px !important;height:50px;" v-model="form.mobileCode">
                    <el-option :key="id" :label="'+'+item.code + '(' + item.countrysEnglish + ')'" :value="item.code"
                               v-for="(item, id) in areaList.phoneCountriesCodeList">
                    </el-option>
                </el-select>
            </div>
            <div class="ita-input-fronts" style="display: inline-block;width:280px;">
                <!--<span v-if="form.mobileCode" style="position:absolute;margin-left:-40px;">+{{form.mobileCode}}</span>-->
                <div class="v-line"></div>
                <input :placeholder="$t('publish.input')" class="iif-input" style="width:280px;" type="text"
                       v-model="form.phone"/>
            </div>
            <!-- <div class="input_box_number">
              <span>+86</span>
              <input type="text" v-model="form.phone" :placeholder="$t('publish.input')">
            </div> -->
            <div class="member_title">
                <span>*</span>
                {{$t("publish.mailbox")}}
            </div>
            <div class="input_box_email">
                <input :placeholder="$t('publish.input')" type="text" v-model="form.mail">
            </div>
            <div class="member_title" style="margin-left: 10px">
                WhatsApp
            </div>
            <div style="display: inline-block;">
                <el-select filterable style="width:150px !important;height:50px;" v-model="form.whatsappCode">
                    <el-option :key="index" :label="'+'+item.code + '(' + item.countrysEnglish + ')'" :value="item.code"
                               v-for="(item, index) in areaList.phoneCountriesCodeList">
                    </el-option>
                </el-select>
            </div>
            <div class="ita-input-fronts" style="display: inline-block;width:280px;">
                <!--<span v-if="form.whatsappCode" style="position:absolute;margin-left:-40px;">+{{form.whatsappCode}}</span>-->
                <div class="v-line"></div>
                <input :placeholder="$t('publish.input')" class="iif-input" style="width:280px;" type="text"
                       v-model="form.whatsApp"/>
            </div>
            <!-- <div class="input_box_email">
              <input type="text" v-model="form.whatsApp" :placeholder="$t('publish.input')">
            </div> -->
            <div class="input_box_search">
                <input placeholder="sitek" type="text" v-model="memberName">
                <div @click="getMemberListByName()" class="fr search_btn cur">
                    <img alt="" src="../../assets/images/icon_search@2x.png" width="18">
                </div>
            </div>
            <div :key="index" class="person_item" v-for="(item,index) in memberList">
                <div @click="()=>{item.isChecked=!item.isChecked;$forceUpdate()}" class="choose_box fl cur">
                    <img :key="item.member_id" alt="" src="../../assets/images/radio_n@2x.png" v-if="!item.isChecked"
                         width="16px">
                    <img :key="item.member_id" alt="" src="../../assets/images/radio_s@2x.png" v-if="item.isChecked"
                         width="16px">
                </div>
                <div class="fl person_msg">
                    <div class="person_msg_one">
                        <img :src="item.avatar" alt="" class="fl">
                        <div class="fl">
                            <h2>{{item.member_name}}</h2>
                            <p>{{item.company_name}} · {{item.position}}</p>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                    <div class="person_msg_two">
                        <div class="person_msg_title fl">
                            <span>*</span>
                            {{$t("publish.phone")}}
                        </div>
                        <div style="display: inline-block;">
                            <el-select @change="changeWhatsApp" filterable style="width:140px !important;height:50px;"
                                       v-model="item.mobileCode">
                                <el-option :key="itemTwo.id"
                                           :label="'+'+itemTwo.code + '(' + itemTwo.countrysChinese + ')'"
                                           :value="itemTwo.code" v-for="itemTwo in areaList.phoneCountriesCodeList">
                                </el-option>
                            </el-select>
                        </div>
                        <div class="ita-input-fronts" style="display: inline-block;width:150px;">
                            <!--<span v-if="item.mobileCode" style="position:absolute;margin-left:-40px;">+{{item.mobileCode}}</span>-->
                            <div class="v-line"></div>
                            <input :placeholder="$t('publish.input')" class="iif-input" style="width:150px;" type="text"
                                   v-model="item.mobileNumber"/>
                        </div>
                        <!-- <div class="person_input_number fl">
                          <span>+86</span>
                          <input type="text" v-model="item.mobileNumber" :placeholder="$t('publish.input')">
                        </div>
                        <div class="clearfix"></div> -->
                    </div>
                    <div class="person_msg_two">
                        <div class="person_msg_title fl">
                            <span>*</span>
                            {{$t("publish.mailbox")}}
                        </div>
                        <div class="person_input_email fl">
                            <input :placeholder="$t('publish.input')" type="text" v-model="item.mailBox">
                        </div>
                        <div class="clearfix"></div>
                    </div>
                    <div class="person_msg_two">
                        <div class="person_msg_title fl">
                            WhatsApp
                        </div>
                        <div style="display: inline-block;">
                            <el-select @change="changeWhatsApp" filterable style="width:140px !important;height:50px;"
                                       v-model="item.whatsappCode">
                                <el-option :key="itemTwo.id"
                                           :label="'+'+itemTwo.code + '(' + itemTwo.countrysChinese + ')'"
                                           :value="itemTwo.code" v-for="itemTwo in areaList.phoneCountriesCodeList">
                                </el-option>
                            </el-select>
                        </div>
                        <div class="ita-input-fronts" style="display: inline-block;width:150px;">
                            <!--<span v-if="item.whatsappCode" style="position:absolute;margin-left:-40px;">+{{item.whatsappCode}}</span>-->
                            <div class="v-line"></div>
                            <input :placeholder="$t('publish.input')" class="iif-input" style="width:150px;" type="text"
                                   v-model="item.whatsApp"/>
                        </div>
                        <!-- <div class="person_input_email fl" style="width: 365px;margin-left: 25px">
                          <input type="text" v-model="item.whatsApp" :placeholder="$t('publish.input')">
                        </div>
                        <div class="clearfix"></div> -->
                    </div>
                </div>
                <div class="clearfix"></div>
            </div>


        </div>
        <div class="dialog-footer" slot="footer">
            <div @click="submit" class="submit-button">{{ $t("bulletFrame.recommendNow") }}</div>
        </div>
    </el-dialog>
</template>

<script>
    import {findMemberListByName} from "@/api/auxiliaryInterfaceApi";
    import {selectCountriesCodeInfo} from '@/api/userApi'


    export default {
        name: "InterviewDialog",
        data() {
            return {
                dialogFormVisible: false,
                form: {
                    phone: '',
                    mail: '',
                    whatsApp: '',
                    reason: '',
                    mobileCode: '86',
                    whatsappCode: '86',
                },
                areaList: [],
                formLabelWidth: '120px',
                memberName: '',//关键字
                memberList: [],//通过关键字检索的会员列表
            };
        },
        components: {},
        props: {},
        mounted() {
            //获取地区编号
            this.getArea();
        },
        methods: {
            changeWhatsApp() {
                this.$forceUpdate();
            },
            //获取地区
            getArea() {
                selectCountriesCodeInfo().then((res) => {
                    if (res.code == 200) {
                        this.areaList = res.data;
                    } else {
                        this.$message.error(res.msg)
                    }
                })
            },
            //打开弹窗
            openDialog() {
                this.dialogFormVisible = true;
            },
            //关闭弹窗
            closeDialog() {
                this.dialogFormVisible = false;
            },
            //提交按钮
            async submit() {
                // this.closeDialog();
                var json = this.memberListJson();
                window.console.log("+++++++++++++=")
                window.console.log(this.form)
                window.console.log(json)
                window.console.log("+++++++++++++=")
                await this.$parent.recommendContactsMember(this.form, json);
            },
            //memberList数据json处理
            memberListJson() {
                let results = [];
                this.memberList.forEach((item) => {
                    console.log(item)
                    if (item.isChecked) {
                        let object = {
                            referralId: item.member_id,
                            referralName: item.member_name,
                            referralAvatar: item.avatar,
                            mobileNumber: item.mobileNumber,
                            mailBox: item.mailBox,
                            whatsApp: item.whatsApp,
                            mobileCode: item.mobileCode,
                            whatsappCode: item.whatsappCode,
                        };
                        results.push(object);
                    }
                });
                console.log(results)
                return JSON.stringify(results);
            },
            //通过关键字检索的会员列表
            getMemberListByName() {
                let formData = {
                    memberName: this.memberName,
                    pageNum: 1,
                    pageSize: 10,
                    token: localStorage.getItem('token'),
                }
                this.isLoading = true;
                findMemberListByName(formData).then((res) => {
                    this.isLoading = false;
                    if (res.code === 200) {
                        this.memberList = res.data;
                        this.memberList.forEach((item) => {
                            item.mobileNumber = '';
                            item.mailBox = '';
                            item.whatsApp = '';
                            item.isChecked = false;
                            item.mobileCode = '86';
                            item.whatsappCode = '86';
                        });
                        console.log("人员列表：", this.memberList)

                    } else if (res.code === 204) {
                        this.$message({message: res.msg, type: 'warning'});
                    } else {
                        this.$message.error(res?.msg ?? "未知错误");
                    }
                })
            },


        },
    }
</script>

<style scoped>
    .ita-input-fronts {
        width: 300px;
        height: 50px;
        background: #fafafa;
        border-radius: 131px;
        margin-top: 0px;
        padding: 0 50px;
        line-height: 50px;
    }

    .iif-input {
        display: inline-block;

        background-color: transparent;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        width: 300px;
    }

    .dialog-wrap .dialog-title {
        text-align: center;
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        line-height: 31px;
        color: #000000;
    }

    .member_box {
        width: 540px;
    }

    .member_title {
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 24px;
        color: #000000;
        margin-bottom: 21px;
    }

    .member_title span {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 19px;
        color: #707070;
    }

    .input_box_number, .input_box_email {
        width: 536px;
        height: 50px;
        background: #FAFAFA;
        border-radius: 131px;
        line-height: 50px;
        padding: 0 26px;
        box-sizing: border-box;
        margin-bottom: 29px;
    }

    .input_box_number input {
        background: #FAFAFA;
        height: 22px;
        border-left: 1px solid #d6d6d6;
        margin-left: 49px;
        padding-left: 45px;
        width: 300px;
    }

    .input_box_email input, .input_box_search input {
        background: #FAFAFA;
        height: 22px;
        width: 300px;
    }

    .input_box_search {
        width: 536px;
        height: 40px;
        background: #FAFAFA;
        border-radius: 131px;
        line-height: 40px;
        padding-left: 26px;
        box-sizing: border-box;
    }

    .search_btn {
        width: 65px;
        height: 40px;
        background: #000000;
        border-radius: 131px;
        text-align: center;
        line-height: 38px;
    }

    .person_item {
        margin-top: 35px;
    }

    .choose_box {
        width: 51px;
        padding-left: 5px;
        box-sizing: border-box;
        padding-top: 17px;
    }

    .person_msg_one {

    }

    .person_msg_one img {
        width: 49px;
        height: 49px;
        border-radius: 50%;
        margin-right: 12px;
    }

    .person_msg_one h2 {
        font-size: 15px;
        font-family: BentonSans;
        font-weight: 500;
        line-height: 17px;
        color: #000000;
        margin-top: 4px;
    }

    .person_msg_one p {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 19px;
        color: #949494;
        margin-top: 6px;
    }

    .person_msg_two {
        margin-top: 26px;
        line-height: 50px;
    }

    .person_msg_title {
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        width: 94px;
    }

    .person_msg_title span {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #707070;
    }

    .person_input_number, .person_input_email {
        width: 391px;
        height: 50px;
        background: #FAFAFA;
        border-radius: 131px;
        padding: 0 26px;
        box-sizing: border-box;
    }

    .person_input_number span {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
    }

    .person_input_number input {
        height: 22px;
        border-left: 1px solid #d6d6d6;
        padding-left: 45px;
        background: #FAFAFA;
        margin-left: 49px;
    }

    .person_input_email input {
        background: #FAFAFA;
    }


    .submit-button {
        width: 140px;
        height: 50px;
        background: #FFFFFF;
        border: 1px solid #707070;
        border-radius: 131px;
        text-align: center;
        line-height: 50px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        margin: 0 auto;
        cursor: pointer;
    }
</style>
