<template>
    <div class="container">
        <BreadcrumbNav :pageName="$t('personalCenter.personCenter')"></BreadcrumbNav>
        <div class="user_wrap auto">
            <!--  基础信息面板  -->
            <div class="user_one">
                <div :style="{backgroundImage:'url('+backgroundImage+')'}" class="user_one_top"
                     style="background-color: black">
                    <div v-if="userInfo.isOneSelf == 1">
                        <ul class="fr user_nav_one">
                            <li @click="$router.push('redact')" class="cur fl">{{
                                $t("accountManagement.editingMaterials") }}
                            </li>
                            <li @click="$router.push('companyDetails')" class="cur fl">{{
                                $t("accountManagement.companyMaintenance") }}
                            </li>
                            <li @click="$router.push('account')" class="cur fl">{{ $t("header.accountManagement") }}
                            </li>
                            <!--<li class="cur fl" @click="$router.push('orderManagement')">订单管理</li>-->
                            <li @click="$router.push('/myProject')" class="cur fl">{{ $t("header.myProject") }}</li>
                            <li @click="$router.push('/wallet')" class="cur fl">{{ $t("header.myWallet") }}</li>
                        </ul>
                        <div @click="isBg = true" class="change_bg cur">
                            {{ $t("personalCenter.changeBackground") }}
                        </div>
                    </div>
                    <div v-if="userInfo.isOneSelf == 0">
                        <ul class="fr user_nav_one">
                            <li @click="$refs.recommendMemberToMember.openDialog()" class="cur fl">{{
                                $t('personalCenter.recommend') }}
                            </li>
                            <li @click="$refs.touSuDialog.openDialog()" class="cur fl">{{ $t("bulletFrame.complaint")
                                }}
                            </li>
                        </ul>

                    </div>
                    <div class="avatar_box" style="padding-top: 0">
                        <img :src="userInfo.personBasicInfo.avatar" alt="" v-if="userInfo.personBasicInfo">
                        <div @click="playAudio" class="audio-play"
                             v-if="userInfo.personBasicInfo&&userInfo.personBasicInfo.voice">
                            <img alt="" src="@/assets/images/yuy@2x.png">
                        </div>
                    </div>
                </div>
                <div class="user_one_body" v-if="userInfo.personBasicInfo">
                    <div class="one_body_top">
                        <div class="ng">
                            <span>{{ userInfo.personBasicInfo.name }}</span>
                            <span>{{ $t("personalCenter.gender") }}</span>
                            <span>{{
                  userInfo.personBasicInfo.gender == 'male' ? $t("personalCenter.male") : userInfo.personBasicInfo.gender == 'female' ? $t("personalCenter.female") : $t("personalCenter.unknown")
                }}</span>
                        </div>
                        <p @click="userInfo.personBasicInfo.companyId?$router.push({path:'/user/companyDetails',query:{id:userInfo.personBasicInfo.companyId}}):null"
                           class="over2 cur"
                           style="width: 480px;">
                            {{ userInfo.personBasicInfo.position }} at {{ userInfo.personBasicInfo.companyName }}</p>
                        <div class="buy_three">
                            <img alt="" class="fl" src="../../assets/images/icon_3min@2x.png">
                            <div class="buy_three_hint fl">
                                <p class="">{{ $t("personalCenter.buy3Minites") }}：</p>
                                <p class="over2" style="width: 480px;">
                                    {{ $t("personalCenter.videoCommunication") }}
                                    <span>${{ userInfo.personBasicInfo.exchangePriceVideo }}</span>
                                    <br/>
                                    {{ $t("personalCenter.audioCommunication") }}
                                    <span>${{ userInfo.personBasicInfo.exchangePriceAudio }}</span>
                                </p>
                            </div>
                            <div class="one_body_top_right" v-if="userInfo.isOneSelf == 0">
                                <div class="fl user_buy_collcet" v-show="userInfo.isReal == 1">
                                    <div @click.prevent="attention($event,1, userInfo.personBasicInfo.id,userInfo.personBasicInfo.name)"
                                         class="attention_btn cur "
                                         v-if="clickFollowed == 0" v-preventReClick>{{ $t("project.follow") }}
                                    </div>
                                    <div @click.prevent="attention($event,2, userInfo.personBasicInfo.id,userInfo.personBasicInfo.name)"
                                         class="cancel_btn cur "
                                         v-else v-preventReClick>{{ $t("project.followed") }}
                                    </div>
                                    <div @click=" $router.push({path: '/project/addProjectOfInterview',
                            query: {inviteeId: userInfo.personBasicInfo.id, inviteeName: userInfo.personBasicInfo.name,exchangePriceAudio:userInfo.personBasicInfo.exchangePriceAudio,exchangePriceVideo:userInfo.personBasicInfo.exchangePriceVideo,},})"
                                         class="buy_btn cur">
                                        {{ $t("personalCenter.buyNow") }}
                                    </div>
                                </div>
                                <div @click="$refs.purchaseNotesDialog.open(purchaseNotesHtml)" class="fl buy_hint cur">
                                    <!--<h2>{{ $t("personalCenter.planExpiry") }}</h2>-->
                                    <p class="over2" style="padding-top: 50px;">{{ $t("user.notesToBuy") }}</p>
                                </div>
                            </div>
                            <div class="clearfix"></div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- 领英工作经历 -->
            <div class="work-experience cur" v-if="userInfo.linkedInExperience&&userInfo.linkedInExperience.length!=0">
                <div class="title">LinkedIn<!--领英工作经历--></div>
                <div :key="index" class="item clearfix1" v-for="(item,index) in userInfo.linkedInExperience">
                    <div class="icon">
                        <img :src="item.logo" alt="">
                    </div>
                    <div class="content clearfix1">
                        <p @click="item.companyId?$router.push({path:'/user/companyDetails',query:{id:item.companyId}}):null">
                            {{ item.name }}</p>
                        <p>{{ item.position }}</p>
                        <p>{{ item.startDate }} - {{ item.endDate }}</p>
                        <div class="desc over3">
                            {{ item.describe }}
                        </div>
                    </div>
                </div>
            </div>

            <!-- CB工作经历 -->
            <div class="work-experience cur" v-if="userInfo.cbExperience&&userInfo.cbExperience.length!=0">
                <div class="title">CB<!--CB工作经历--></div>
                <div :key="index" class="item clearfix1" v-for="(item,index) in userInfo.cbExperience">
                    <div class="icon">
                        <img :src="item.logo" alt="">
                    </div>
                    <div class="content clearfix1">
                        <p @click="item.companyId?$router.push({path:'/user/companyDetails',query:{id:item.companyId}}):null">
                            {{ item.companyName }}</p>
                        <p>{{ item.position }}</p>
                        <p>{{ item.startDate }} - {{ item.endDate }}</p>
                        <div class="desc over3">
                            {{ item.workDescribe }}
                        </div>
                    </div>
                </div>
            </div>

            <!-- 教育 -->
            <div class="education" v-if="userInfo.educationExperience&&userInfo.educationExperience.length!=0">
                <div class="title">Education</div>
                <!--<p class="desc">Bill Gates Studied at Harvard University</p>-->
                <div :key="index" class="item" v-for="(item,index) in userInfo.educationExperience">
                    <!--<div class="icon">-->
                    <!--  <img :src="item.logo" alt="">-->
                    <!--</div>-->
                    <div class="intro">
                        <p @click="item.companyId?$router.push({path:'/user/companyDetails',query:{id:item.companyId}}):null">
                            {{ item.university }}</p>
                        <p>{{ item.major }}</p>
                        <p>{{ item.startDate }} - {{ item.endDate }}</p>
                    </div>
                </div>
            </div>

            <!-- 维基百科 -->
            <div class="wiki" v-if="userInfo.wikipedia">
                <div class="icon">
                    <img alt="" src="@/assets/images/weijibaike.png">
                </div>
                <!--<div class="intro">-->
                <!--  <p>Wikipedia</p>-->
                <!--  <p>{{ userInfo.wikipedia }}</p>-->
                <!--</div>-->
                <a :href="userInfo.wikipedia" class="open" target="_blank">{{ $t("personalCenter.openTo") }}</a>
            </div>

            <!-- YouTuBe -->
            <div class="YouTuBe" v-if="userInfo.youTuBeList&&userInfo.youTuBeList.length!=0">
                <div class="title">YouTube</div>
                <div class="video-area">
                    <div :key="index" class="item" v-for="(item,index) in userInfo.youTuBeList">
                        <p>{{ item.link_title }}</p>
                        <div class="player-box">
                            <iframe :src="item.link_url" allowfullscreen height="500" width="1000"></iframe>
                        </div>
                    </div>
                </div>
            </div>

            <!-- 博客 -->
            <div class="blog" v-if="userInfo.myBlog">
                <div class="title">My blog</div>
                <div style="margin-top: 30px" v-html="userInfo.myBlog"></div>
                <!--下载附件-->
                <div class="download-attachment" v-if="this.userInfo.personBasicInfo.enclosure">
                    <h1>{{ $t("personalCenter.previewAttachment") }}</h1>
                    <div @click="downloadAttachment" class="download-btn cur">{{ $t("personalCenter.openTo") }}</div>
                </div>
            </div>

            <!-- 个人投资 -->
            <div class="investments" v-if="userInfo.personInvestList&&userInfo.personInvestList.length!=0">
                <div class="title">Personal Investments</div>
                <p class="count">lnvest Number</p>
                <p class="count-value">{{ userInfo.personInvestList.length }}</p>
                <table>
                    <tr>
                        <td>Date</td>
                        <td>Name</td>
                        <td>URL</td>
                        <td>Lead Investor</td>
                        <td>Funding Round</td>
                        <td>Money Raised</td>
                    </tr>
                    <tr :key="index" v-for="(item,index) in userInfo.personInvestList">
                        <td>{{ item.date }}</td>
                        <td>
                            <img alt="" src="@/assets/images/icon_link_2@2x.png">
                            <span>{{ item.name }}</span>
                        </td>
                        <td>
                            <a :href="item.url" :title="item.url" class="over" style="text-decoration: underline"
                               target="_blank">{{ item.url }}</a>
                        </td>
                        <td>{{ item.leadInvestor }}</td>
                        <td>
                            <img alt="" src="@/assets/images/icon_link_2@2x.png">
                            <span>{{ item.fundingRound }}</span>
                        </td>
                        <td>{{ item.moneyRaised }}</td>
                    </tr>
                </table>
            </div>

            <!--   Exits   -->
            <div class=" exits" v-if="userInfo.personExitList&&userInfo.personExitList.length!=0">
                <div class="title">Exits</div>
                <p class="count">Number of Exits</p>
                <p class="count-value">{{ userInfo.personExitList.length }}</p>
                <div class="seven_list">
                    <div :key="index" class="seven_item fl" v-for="(item,index) in userInfo.personExitList">
                        <div class="seven_item_left fl">
                            <img :src="item.logo" alt="" style="width: 100%;height: 100%">
                        </div>
                        <div class="seven_item_right fl">
                            <h2>{{ item.name }}</h2>
                            <a :href="item.url" :title="item.url" class="over"
                               style="text-decoration: underline;margin: 10px 0;display: block"
                               target="_blank">{{ item.url }}</a>

                            <div>{{ item.profile }}
                            </div>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                    <div class="clearfix"></div>
                </div>
            </div>

            <!-- 平台 -->
            <div class="platform clearfix1" v-if="JSON.stringify(userInfo.sjmtMap)!='{}'">
                <div class="item" v-if="userInfo.sjmtMap&&userInfo.sjmtMap.google">
                    <img alt="" src="@/assets/images/sj_iocn_2@2x.png">
                    <a :href="userInfo.sjmtMap.google"
                       :title="userInfo.sjmtMap.google"
                       class="over"
                    >{{ userInfo.sjmtMap.google }}</a>
                </div>
                <div class="item" v-if="userInfo.sjmtMap&&userInfo.sjmtMap.linkedin">
                    <img alt="" src="@/assets/images/sj_iocn_3@2x.png">
                    <a :href="userInfo.sjmtMap.linkedin"
                       :title="userInfo.sjmtMap.linkedin"
                       class="over"
                    >{{ userInfo.sjmtMap.linkedin }}</a>
                </div>
                <div class="item" v-if="userInfo.sjmtMap&&userInfo.sjmtMap.cb">
                    <img alt="" src="@/assets/images/sj_iocn_4@2x.png">
                    <a :href="userInfo.sjmtMap.cb"
                       :title="userInfo.sjmtMap.cb"
                       class="over"
                    >{{ userInfo.sjmtMap.cb }}</a>
                </div>
                <div class="item" v-if="userInfo.sjmtMap&&userInfo.sjmtMap.xing">
                    <img alt="" src="@/assets/images/sj_iocn_5@2x.png">
                    <a :href="userInfo.sjmtMap.xing"
                       :title="userInfo.sjmtMap.xing"
                       class="over"
                    >{{ userInfo.sjmtMap.xing }}</a>
                </div>
                <div class="item" v-if="userInfo.sjmtMap&&userInfo.sjmtMap.facebook">
                    <img alt="" src="@/assets/images/sj_iocn_6@2x.png">
                    <a :href="userInfo.sjmtMap.facebook"
                       :title="userInfo.sjmtMap.facebook"
                       class="over"
                    >{{ userInfo.sjmtMap.facebook }}</a>
                </div>
                <div class="item" v-if="userInfo.sjmtMap&&userInfo.sjmtMap.twitter">
                    <img alt="" src="@/assets/images/sj_iocn_7@2x.png">
                    <a :href="userInfo.sjmtMap.twitter"
                       :title="userInfo.sjmtMap.twitter"
                       class="over"
                    >{{ userInfo.sjmtMap.twitter }}</a>
                </div>
                <div class="item" v-if="userInfo.sjmtMap&&userInfo.sjmtMap.microblog">
                    <img alt="" src="@/assets/images/sj_iocn_8@2x.png">
                    <a :href="userInfo.sjmtMap.microblog"
                       :title="userInfo.sjmtMap.microblog"
                       class="over"
                    >{{ userInfo.sjmtMap.microblog }}</a>
                </div>
                <div class="item" v-if="userInfo.sjmtMap&&userInfo.sjmtMap.weChat">
                    <img alt="" src="@/assets/images/sj_iocn_9@2x.png">
                    <a :href="userInfo.sjmtMap.weChat"
                       :title="userInfo.sjmtMap.weChat"
                       class="over"
                    >{{ userInfo.sjmtMap.weChat }}</a>
                </div>
            </div>

            <!--基本信息-->
            <div class="basic clearfix1"
                 v-if="userInfo.dqtzMap&&(userInfo.dqtzMap.investorStage||userInfo.dqtzMap.location||userInfo.dqtzMap.investorType)">
                <div class="item" v-if="userInfo.dqtzMap.location ">
                    <div class="key">location</div>
                    <div class="value">{{ userInfo.dqtzMap.location }}</div>
                </div>
                <div class="item" v-if="userInfo.dqtzMap.investorStage">
                    <div class="key">Investor Stage</div>
                    <div class="value">{{ userInfo.dqtzMap.investorStage }}</div>
                </div>
                <div class="item" v-if="userInfo.dqtzMap.investorType  ">
                    <div class="key">Investor Type</div>
                    <div class="value">{{ userInfo.dqtzMap.investorType }}</div>
                </div>
            </div>

            <!--   评价   -->
            <div class="user_four" v-if="userInfo.memberEvaluateRecords&&userInfo.memberEvaluateRecords.length!=0">
                <h2>{{ $t("personalCenter.evaluate") }}</h2>
                <div class="user_four_list">
                    <div :key="index" class="user_four_item" v-for="(item,index) in userInfo.memberEvaluateRecords">
                        <img :src="item.memberAvatar" alt="" class="fl">
                        <div class="fl four_item_msg">
                            <h2>
                                <span class="fl">{{ item.memberName }}</span>
                                <span class="fr">{{ item.createTime }}</span>
                                <div class="clearfix"></div>
                            </h2>
                            <ul>
                                <li :key="index" class="fl" v-for="index of parseInt(item.rating)"><img alt=""
                                                                                                        src="../../assets/images/star_s@2x.png"
                                                                                                        width="20">
                                </li>
                                <div class="clearfix"></div>
                            </ul>
                            <p class="">
                                {{ item.content }}
                            </p>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                </div>
            </div>
            <!--   投诉   -->
            <div class="user_four" v-if="userInfo.memberComplaintRecords&&userInfo.memberComplaintRecords.length!=0">
                <h2>{{ $t("bulletFrame.complaint") }}</h2>
                <div class="user_four_list">
                    <div :key="index" class="user_four_item" v-for="(item,index) in userInfo.memberComplaintRecords">
                        <img :src="item.complaintAvatar" alt="" class="fl">
                        <div class="fl four_item_msg">
                            <h2>
                                <span class="fl">{{ item.complaintPerson }}</span>
                                <span class="fr">{{ item.createTime }}</span>
                                <div class="clearfix"></div>
                            </h2>

                            <p class="">
                                {{ item.complaintContent }}
                            </p>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                </div>
            </div>
        </div>
        <!--  更换背景  -->
        <div class="pop" v-if="isBg">
            <div class="pop_bg">
                <div class="pop_close">
                    <img @click="isBg = false"
                         alt=""
                         class="cur" src="../../assets/images/icon_closed@2x.png" width="16px">
                </div>
                <div class="pop_title">
                    {{ $t("bulletFrame.updatePersonBack") }}
                </div>
                <div class="pop_bg_img">
                    <img :src="backgroundImage" alt="">
                </div>
                <div class="pop_hint_one">{{ $t("bulletFrame.personMainPage") }}</div>
                <div class="pop_hint_two">PNG,JPG
                    <br>
                    Cropping
                </div>
                <el-upload
                        :action="actionUrl"
                        :data="uploadParams"
                        :on-change="handleChange"
                        :on-error="handleError"
                        :on-success="handleSuccess"
                        :show-file-list="false"
                        class="upload-avatar auto"
                        list-type="picture"
                        ref="upload"
                >
                    <el-button size="small" type="primary">{{ $t("bulletFrame.uploadPictures") }}</el-button>
                </el-upload>
                <div @click="updateUserBackground" class="pop_save_btn auto cur">
                    {{ $t("bulletFrame.saveSettings") }}
                </div>
            </div>
        </div>
        <transition name="fade">
            <loading v-if="isLoading"></loading>
        </transition>

        <TouSuDialog ref="touSuDialog"/>
        <RecommendMemberToMember ref="recommendMemberToMember"/>
        <PurchaseNotesDialog ref="purchaseNotesDialog"/>
    </div>
</template>

<script>
    import BreadcrumbNav from "@/components/BreadcrumbNav";
    import {findMember, memberComplaint, recommendContactsMember, updateHomeBackground} from "@/api/userApi";
    import Loading from '@/components/loading'
    import {followTalent} from "@/api/memberApi";
    import TouSuDialog from "../dialog/TouSuDialog";
    import RecommendMemberToMember from "@/components/dialog/RecommendMemberToMember";
    import {uploadAvatarOrBackdrop} from "@/api/uploadApi";
    import {notesToBuy} from "@/api/auxiliaryInterfaceApi";
    import PurchaseNotesDialog from "@/components/dialog/PurchaseNotesDialog";

    export default {
        name: "index",
        data() {
            return {
                actionUrl: uploadAvatarOrBackdrop,
                //上传文件携带参数
                uploadParams: {
                    type: 2
                },
                backgroundImage: '',
                edited: '',
                userType: 1,
                talentId: '',
                isBg: false,
                memberMsg: [],//会员基本信息
                isLoading: false,
                userInfo: {},
                basicInfo: {},//人才基本信息
                educationExperience: [],//教育经历
                evaluateRecords: [],//评价记录
                complaintRecords: [],//投诉记录
                personalInvestments: {},//个人投资
                partnerInvestments: {},//合伙投资
                exitsInvestments: {},//退出投资
                currentJob: [],//现值位
                postJob: [],//过去职位
                currentBoardadvisorrole: {},//现董事及顾问
                postBoardadvisorrole: {},//过去董事及顾问
                goumai: 'XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX',//购买须知
                clickFollowed: '0',//0未关注，1关注
                audio: '',
                purchaseNotes: [],
            }
        },
        created() {
            this.talentId = this.$route.query.talentId;
        },

        mounted() {
            this.getUserInfo();
            this.notesToBuy();
            this.audio = new Audio();
        },
        computed: {
            purchaseNotesHtml() {
                // return this.$i18n.locale == 'zh' ? this.purchaseNotes.content : this.purchaseNotes.contentEnglish;
                return this.purchaseNotes;
            }
        },
        components: {
            BreadcrumbNav, Loading, TouSuDialog, RecommendMemberToMember, PurchaseNotesDialog
        },
        methods: {
            goSchool(url) {
                window.open(url);
            },
            downloadAttachment() {
                window.open(this.userInfo.personBasicInfo.enclosure)
            },
            playAudio() {
                if (this.audio.paused) {
                    this.audio.play();
                } else {
                    if (this.audio.currentTime != 0) this.audio.pause();
                }
                console.log(this.audio.currentTime)
            },
            play(e) {
                e.currentTarget.style.display = "none"
                let video = e.currentTarget.previousElementSibling;
                video.play();
            },
            controlsShow(e) {
                let video = e.currentTarget.firstChild;
                video.controls = true;
            },
            controlsHide(e) {
                let video = e.currentTarget.firstChild;
                video.controls = false;
            },
            handleChange(file, fileList) {
                if (fileList.length > 1) {
                    this.$refs.upload.uploadFiles.splice(0, 1)
                }
            },
            handleSuccess(response, file, fileList) {
                if (response.code === 200) {
                    this.$message({
                        message: this.$t('user.ploadSucceeded'),
                        type: 'success'
                    });
                    this.backgroundImage = response.data.path;
                } else {
                    this.$message.error(response.msg)
                }
            },
            handleError(err, file, fileList) {
                this.$message.error(err)
            },
            //修改背景图
            updateUserBackground() {
                let formData = {
                    homeBackground: this.backgroundImage,
                    token: localStorage.getItem('token')
                }
                updateHomeBackground(formData).then((res) => {
                    if (res.code == 200) {
                        this.$message.success(res.msg)
                        this.isBg = false
                        this.getUserInfo()
                    } else {
                        this.$message.error(res.msg)
                    }
                })
            },
            //关注
            attention(event, type, id, name) {
                if (event.target.disabled) {
                    // 点击太频繁了
                    this.$message.warning(this.$t('myProject.clickFrequentMsg'))
                    return
                }
                let formData = {
                    operateType: type,
                    talentId: id,
                    talentName: name,
                    token: localStorage.getItem('token')
                }
                followTalent(formData).then((res) => {
                    if (res.code == 200) {
                        if (type == 1) {
                            this.clickFollowed = 1
                            this.$message.success(this.$t('myProject.followMsg.followSuccess'))//关注成功

                        } else {
                            this.clickFollowed = 0
                            this.$message.success(this.$t('myProject.followMsg.unfollowSuccess'))//取消关注成功

                        }
                    }
                })
            },
            //投诉
            memberComplaint(form) {
                let formData = {
                    talentId: this.userInfo.personBasicInfo.id,
                    complaintContent: form.reason,
                    token: localStorage.getItem('token'),
                    mobileNumber: form.phone,
                    mailBox: form.mail,
                    whatsApp: form.whatsApp,
                    mobileCode: form.mobileCode,
                    whatsappCode: form.whatsappCode,
                }
                memberComplaint(formData).then((res) => {
                    if (res.code == 200) {
                        this.$message.success(res.msg)
                    } else {
                        this.$message.error(res.msg)
                    }
                })
            },
            //获取会员信息
            getUserInfo() {
                let formData = {
                    token: localStorage.getItem('token'),
                    talentId: this.talentId ?? "",
                }
                this.isLoading = true;
                findMember(formData).then((res) => {
                    this.isLoading = false;
                    if (res.code == 200) {
                        this.userInfo = res.data;
                        this.backgroundImage = this.userInfo.personBasicInfo.homeBackground;
                        this.audio.src = this.userInfo.personBasicInfo.voice;
                        this.clickFollowed = res.data.followed;
                    } else {
                        this.$message.error(res.msg)
                    }
                })
            },
            //购买须知
            notesToBuy() {
                let formData = {}
                notesToBuy(formData).then((res) => {
                    if (res.code == 200) {
                        this.purchaseNotes = res.data
                    } else {
                        this.$message.error(res.msg)
                    }
                })
            },
            //会员给会员推荐其他会员
            recommendContactsMember(form, json) {
                console.log("json")
                console.log(json)
                let formData = {
                    receiveId: this.userInfo.personBasicInfo.id,
                    mobileNumber: form.phone,
                    mailBox: form.mail,
                    whatsApp: form.whatsApp,
                    referralInfo: json,
                    mobileCode: form.mobileCode,
                    whatsappCode: form.whatsappCode,
                    token: localStorage.getItem('token'),
                }
                recommendContactsMember(formData).then((res) => {
                    this.isLoading = false;
                    if (res.code === 200) {
                        this.$message({message: res.msg, type: 'success'});
                        this.$router.go(-1)
                    } else if (res.code === 400) {
                        this.$message({message: res.msg, type: 'warning'});
                    } else {
                        this.$message.error(res?.msg ?? "未知错误");
                    }
                })
            },
        },
    }
</script>

<style lang="scss" scoped>
    .blog ::v-deep.ql-video {
        width: 1000px;
        height: 500px;
    }

    /*工作经历*/
    .work-experience {
        width: 100%;
        background: #FFFFFF;
        border-radius: 4px;
        margin-top: 20px;
        padding: 35px 65px 40px 45px;
        box-sizing: border-box;

        .title {
            font-size: 26px;
            font-family: Roboto;
            font-weight: bold;
            color: #000000;
            margin-bottom: 33px;
        }

        .item {
            margin-bottom: 40px;

            .icon {
                float: left;
                width: 102px;
                height: 102px;
                border-radius: 7px;
                margin-right: 22px;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            .content {
                float: left;

                > p {
                    &:first-child {
                        font-size: 13px;
                        font-family: Montserrat;
                        font-weight: bold;
                        color: #333333;
                    }

                    &:nth-child(2) {
                        font-size: 14px;
                        font-family: Montserrat;
                        font-weight: 400;
                        color: #000000;
                        margin-top: 12px;
                    }

                    &:nth-child(3) {
                        font-size: 14px;
                        font-family: Montserrat;
                        font-weight: 400;
                        color: #000000;
                        margin-top: 12px;
                    }
                }

                .desc {
                    width: 965px;
                    margin-top: 18px;
                    line-height: 22px;
                }

            }
        }
    }

    /*教育*/
    .education {
        width: 100%;
        background: #FFFFFF;
        border-radius: 4px;
        margin-top: 20px;
        padding: 35px 65px 40px 45px;
        box-sizing: border-box;

        .title {
            font-size: 26px;
            font-family: Roboto;
            font-weight: bold;
            color: #000000;
            margin-bottom: 33px;
        }

        .desc {
            font-size: 14px;
            font-family: Montserrat;
            font-weight: 400;
            color: #000000;
        }

        .item {
            margin-top: 37px;

            .icon {
                display: inline-block;
                vertical-align: middle;
                width: 102px;
                height: 102px;
                border-radius: 7px;
                margin-right: 23px;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            .intro {
                display: inline-block;
                vertical-align: middle;

                p {
                    &:first-child {
                        font-size: 16px;
                        font-family: Montserrat;
                        font-weight: bold;
                        color: #333333;
                    }

                    &:nth-child(2) {
                        font-size: 14px;
                        font-family: Montserrat;
                        font-weight: 400;
                        color: #000000;
                        margin-top: 8px;

                    }

                    &:last-child {
                        font-size: 14px;
                        font-family: Montserrat;
                        font-weight: 400;
                        color: #000000;
                        margin-top: 13px;
                    }
                }
            }
        }
    }

    /*维基百科*/
    .wiki {
        width: 100%;
        background: #FFFFFF;
        border-radius: 4px;
        margin-top: 20px;
        padding: 35px 65px 40px 45px;
        box-sizing: border-box;

        .icon {
            display: inline-block;
            vertical-align: middle;
            width: 95px;
            height: 95px;
            border-radius: 7px;
            margin-right: 16px;

            img {
                width: 100%;
                height: 100%;
            }
        }

        .open {
            display: block;
            cursor: pointer;
            margin-top: 25px;
            width: 162px;
            height: 50px;
            background: linear-gradient(180deg, #000000 0%, #232321 100%);
            border-radius: 131px;
            text-align: center;
            line-height: 50px;
            color: #FFFFFF;
            font-weight: bold;
            font-size: 20px;
        }

        .intro {
            display: inline-block;
            vertical-align: middle;

            p {
                &:first-child {
                    font-size: 24px;
                    font-family: Montserrat;
                    font-weight: bold;
                    color: #000000;
                }

                &:last-child {
                    cursor: pointer;
                    text-decoration: underline;
                    font-size: 14px;
                    font-family: Montserrat;
                    font-weight: bold;
                    color: #000000;
                    margin-top: 11px;
                }
            }
        }
    }

    /*YouTuBe*/
    .YouTuBe {
        width: 100%;
        background: #FFFFFF;
        border-radius: 4px;
        margin-top: 20px;
        padding: 35px 45px 40px 45px;
        box-sizing: border-box;

        .title {
            font-size: 26px;
            font-family: Roboto;
            font-weight: bold;
            color: #000000;
            margin-bottom: 8px;
        }

        .video-area {
            .item {
                margin-bottom: 50px;

                p {
                    font-size: 14px;
                    font-family: Montserrat;
                    font-weight: 400;
                    color: #000000;
                    margin-bottom: 14px;
                }

            }
        }
    }

    /* blog */
    .blog {
        width: 100%;
        background: #FFFFFF;
        border-radius: 4px;
        margin-top: 20px;
        padding: 35px 65px 40px 45px;
        box-sizing: border-box;

        .title {
            font-size: 26px;
            font-family: Roboto;
            font-weight: bold;
            color: #000000;
            margin-bottom: 33px;
        }

        .download-attachment {
            margin-top: 46px;

            h1 {
                font-size: 26px;
                font-family: Roboto;
                font-weight: bold;
                color: #000000;
            }

            .download-btn {
                margin-top: 19px;
                width: 162px;
                height: 50px;
                background: linear-gradient(180deg, #000000 0%, #232321 100%);
                border-radius: 131px;
                text-align: center;
                line-height: 50px;

                font-size: 20px;
                font-family: Microsoft YaHei;
                color: #FFFFFF;
            }
        }
    }

    /* 个人投资 */
    .investments {
        width: 100%;
        background: #FFFFFF;
        border-radius: 4px;
        margin-top: 20px;
        padding: 35px 65px 40px 45px;
        box-sizing: border-box;

        .title {
            font-size: 26px;
            font-family: Roboto;
            font-weight: bold;
            color: #000000;
            margin-bottom: 33px;
        }

        .count {
            font-size: 14px;
            font-family: Montserrat;
            font-weight: 400;
            color: #000000;
        }

        .count-value {
            font-size: 20px;
            font-family: Montserrat;
            font-weight: bold;
            color: #000000;
            margin-top: 13px;
        }

        table {
            margin-top: 33px;
            width: 100%;

            tr {
                height: 45px;

                &:first-child {
                    background: #FAFAFA;

                    td {
                        color: #949494;
                    }
                }

                td {
                    text-align: center;
                    vertical-align: middle;
                    font-size: 14px;
                    font-family: Montserrat;
                    font-weight: 400;
                    color: #333333;

                    &:nth-child(3) {
                        a {
                            display: inline-block;
                            width: 250px;
                        }
                    }

                    img {
                        width: 25px;
                        margin-right: 10px;
                    }

                }
            }
        }
    }

    /* exits */
    .exits {
        width: 100%;
        background: #FFFFFF;
        border-radius: 4px;
        margin-top: 20px;
        padding: 35px 65px 40px 45px;
        box-sizing: border-box;

        .title {
            font-size: 26px;
            font-family: Roboto;
            font-weight: bold;
            color: #000000;
            margin-bottom: 33px;
        }

        .count {
            font-size: 14px;
            font-family: Montserrat;
            font-weight: 400;
            color: #000000;
        }

        .count-value {
            font-size: 20px;
            font-family: Montserrat;
            font-weight: bold;
            color: #000000;
            margin-top: 13px;
        }

        .seven_list {
            margin-top: 36px;
        }

        .seven_item {
            width: 503px;
            margin-right: 55px;
            margin-bottom: 31px;
        }

        .seven_item:nth-child(2n) {
            margin-right: 0;
        }

        .seven_item_left {
            width: 102px;
            height: 102px;
            background: #333333;
            border-radius: 7px;
            text-align: center;
            font-size: 63px;
            font-weight: bold;
            color: #FFFFFF;
            margin-right: 23px;
            overflow: hidden;
            border: 1px solid #000000;
        }

        .seven_item_right, .seven_item_right_two {
            width: 375px;
        }

        .seven_item_right h2 {
            font-size: 13px;
            font-family: Montserrat;
            font-weight: bold;
            line-height: 16px;
            color: #333333;
            margin-top: 15px;
        }

        .seven_item_right p {
            font-size: 14px;
            font-family: Montserrat;
            font-weight: 400;
            line-height: 18px;
            color: #000000;
            margin: 11px 0;
        }

        .seven_item_right div {
            font-size: 14px;
            font-family: Montserrat;
            font-weight: 400;
            line-height: 18px;
            color: #000000;
        }

        .seven_item_right_two h2 {
            font-size: 14px;
            font-family: Montserrat;
            font-weight: bold;
            line-height: 18px;
            color: #333333;
            margin-top: 14px;
            margin-bottom: 10px;
        }

        .seven_item_right_two p {
            margin-bottom: 8px;
            font-size: 14px;
            font-family: Montserrat;
            font-weight: 400;
            line-height: 18px;
            color: #000000;
        }
    }

    /* 平台 */
    .platform {
        width: 100%;
        background: #FFFFFF;
        border-radius: 4px;
        margin-top: 20px;
        padding: 88px 180px 40px 140px;
        box-sizing: border-box;

        .item {
            float: left;
            margin-right: 100px;
            margin-bottom: 50px;

            &:nth-child(4n) {
                margin-right: 0;
            }

            img {
                display: block;
                width: 85px;
                //height: 85px;
                margin: 0 auto;
            }

            a {
                display: block;
                width: 140px;
                text-align: center;
                font-size: 14px;
                font-family: Montserrat;
                font-weight: 400;
                color: #000000;
                margin-top: 11px;
            }
        }
    }

    /* 基础信息 */
    .basic {
        width: 100%;
        background: #FFFFFF;
        border-radius: 4px;
        margin-top: 20px;
        padding: 38px 67px;
        box-sizing: border-box;

        .item {
            float: left;
            margin-right: 100px;

            .key {
                font-size: 14px;
                font-family: Montserrat;
                font-weight: 400;
                color: #000000;
            }

            .value {
                font-size: 15px;
                font-family: Montserrat;
                font-weight: 500;
                color: #000000;
                margin-top: 12px;
            }
        }
    }

    .clearfix1 {
        *zoom: 1;

        &:before, &:after {
            content: " ";
            display: table;
        }

        &:after {
            clear: both;
        }
    }

    /*myblog*/
    .my-blog {
        background-color: #FFFFFF;
        margin-top: 20px;
        padding: 42px 67px 60px;
    }

    .container {
        width: 100%;
        min-height: 100vh;
        background: #FAFAFA;
    }

    .user_wrap {
        width: 1200px;
    }

    .user_one {
        margin-top: 34px;
    }

    .user_one_top {
        position: relative;
        width: 1200px;
        height: 141px;
        background: url("../../assets/images/defaultbg@2x.png") no-repeat;
        background-size: 100% 100%;
        border-radius: 4px 4px 0px 0px;
        padding-top: 34px;
        box-sizing: border-box;
    }

    .user_nav_one {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 19px;
        color: #FFFFFF;
        margin-right: 37px;
    }

    .user_nav_one li {
        margin-left: 50px;
    }

    .change_bg {
        position: absolute;
        bottom: 17px;
        right: 37px;
        width: 155px;
        height: 34px;
        border: 1px solid #949494;
        border-radius: 131px;
        box-sizing: border-box;
        text-align: center;
        line-height: 32px;
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #FFFFFF;
    }

    .avatar_box {
        position: absolute;
        bottom: -53px;
        left: 64px;
        width: 130px;
        height: 130px;
        background: #FFFFFF;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.06);
        border-radius: 50%;
        text-align: center;
        line-height: 128px;

        .audio-play {
            cursor: pointer;
            z-index: 99;
            position: absolute;
            right: 0;
            bottom: 0;
            width: 36px;
            height: 36px;
            background: #333333;
            border-radius: 50%;
            //background: url("../../assets/images/yuy@2x.png") center;
            //background-size:15px 20px ;
            img {
                display: block;
                width: 15px;
                height: 20px;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);

            }
        }
    }

    .avatar_box img {
        width: 120px;
        height: 120px;
        border-radius: 50%;
    }

    .user_one_body {
        width: 1200px;
        background: #FFFFFF;
        opacity: 1;
        border-radius: 4px;
    }

    .one_body_top {
        position: relative;
        padding: 65px 66px 0;
        box-sizing: border-box;

        .ng {
            span {
                vertical-align: baseline;

                &:first-child {
                    font-size: 26px;
                    font-family: BentonSans;
                    font-weight: 500;
                    color: #000000;
                    margin-right: 33px;
                }

                &:nth-child(2) {
                    font-size: 14px;
                    font-family: Montserrat;
                    font-weight: 400;
                    color: #000000;
                    margin-right: 11px;
                }

                &:nth-child(3) {
                    font-size: 15px;
                    font-family: Montserrat;
                    font-weight: 500;
                    line-height: 0px;
                    color: #000000;
                }
            }
        }
    }

    .one_body_top > h2 {
        font-size: 26px;
        font-family: BentonSans;
        font-weight: 500;
        line-height: 30px;
        color: #000000;
    }

    .one_body_top > p {
        font-size: 18px;
        font-family: Roboto;
        font-weight: 400;
        line-height: 22px;
        color: #000000;
        margin-top: 12px;
    }

    .one_body_top_right {
        position: absolute;
        top: 0;
        right: 0;
        height: 253px;
        padding-right: 67px;
    }

    .attention_btn {
        margin-top: 67px;
        margin-bottom: 34px;
    }

    .cancel_btn {
        width: 162px;
        height: 50px;
        border: 1px solid #F1F1F1;
        border-radius: 25px;
        box-sizing: border-box;
        text-align: center;
        line-height: 48px;
        background: #FFFFFF;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        margin-top: 67px;
        margin-bottom: 34px;
    }

    .attention_btn, .buy_btn {
        width: 162px;
        height: 50px;
        background: linear-gradient(180deg, #000000 0%, #232321 100%);
        border-radius: 131px;
        text-align: center;
        line-height: 50px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #FFFFFF;
    }

    .buy_hint {
        width: 321px;
        height: 134px;
        background: url(../../assets/images/gmxzbg@2x.png) no-repeat;
        background-size: 100% 100%;
        border-radius: 4px;
        margin-top: 67px;
        margin-left: 64px;
    }

    .buy_hint h2 {
        font-size: 24px;
        font-family: BentonSans;
        font-weight: 900;
        line-height: 28px;
        color: #FFFFFF;
        margin-top: 26px;
        margin-left: 30px;
        margin-bottom: 10px;
    }

    .buy_hint p {
        width: 260px;
        font-size: 16px;
        font-family: Montserrat;
        font-weight: 400;
        line-height: 36px;
        color: #FFFFFF;
        margin-left: 30px;
    }

    .buy_three {
        margin-top: 23px;
        padding-bottom: 45px;
    }

    .buy_three img {
        width: 50px;
        height: 50px;
    }

    .buy_three_hint {
        margin-left: 10px;
    }

    .buy_three_hint p {
        font-size: 18px;
        font-family: Roboto;
        font-weight: 400;
        line-height: 22px;
        color: #000000;
        margin-bottom: 6px;
    }

    .buy_three_hint span {
        font-size: 20px;
        font-family: Roboto;
        font-weight: bold;
        line-height: 26px;
        color: #000000;
    }


    .user_two {
        width: 1200px;
        height: 571px;
        background: #FFFFFF;
        border-radius: 4px;
        margin-top: 20px;
        padding: 43px 0 0 67px;
        box-sizing: border-box;
    }

    .user_two > h2 {
        font-size: 26px;
        font-family: Roboto;
        font-weight: bold;
        line-height: 34px;
        color: #000000;
    }

    .user_two > p {
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        line-height: 26px;
        color: #000000;
        margin-top: 31px;
    }

    .user_two_item {
        margin-top: 9px;
    }

    .user_two_item img {
        width: 102px;
        height: 102px;
    }

    .two_item_msg {
        width: 969px;
        margin-left: 18px;
        margin-bottom: 40px;
    }

    .two_item_msg > h2 {
        font-size: 17px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 22px;
        color: #000000;
        margin-bottom: 12px;
    }

    .two_item_msg > p {
        font-size: 14px;
        font-family: Montserrat;
        font-weight: 400;
        line-height: 23px;
        color: #707070;
    }

    .user_three {
        width: 1200px;
        height: 321px;
        background: #FFFFFF;
        border-radius: 4px;
        margin-top: 20px;
        padding: 37px 0 52px 45px;
        box-sizing: border-box;
    }

    .user_three > h2 {
        font-size: 26px;
        font-family: Roboto;
        font-weight: bold;
        line-height: 34px;
        color: #000000;
        margin-bottom: 28px;
    }

    .user_three > p {
        font-size: 14px;
        font-family: Montserrat;
        font-weight: 400;
        line-height: 18px;
        color: #000000;
        margin-bottom: 37px;
    }

    .user_three_msg {

    }

    .user_three_msg .letter {
        width: 102px;
        height: 102px;
        background: #333333;
        border-radius: 7px;
        line-height: 102px;
        text-align: center;
        font-size: 63px;
        font-family: Roboto;
        font-weight: bold;
        color: #FFFFFF;
        margin-right: 23px;
    }

    .three_msg_box h2 {
        font-size: 13px;
        font-family: Montserrat;
        font-weight: bold;
        line-height: 16px;
        color: #333333;
        margin-top: 15px;
    }

    .three_msg_box p {
        font-size: 14px;
        font-family: Montserrat;
        font-weight: 400;
        line-height: 18px;
        color: #000000;
        margin-top: 10px;
    }

    .user_four {
        width: 1200px;
        background: #FFFFFF;
        border-radius: 4px;
        margin-top: 20px;
        padding: 47px 66px 68px;
        box-sizing: border-box;
    }

    .user_four > h2 {
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        line-height: 31px;
        color: #000000;
    }

    .user_four_item {
        margin-top: 54px;
    }

    .user_four_item > img {
        width: 67px;
        height: 67px;
        border-radius: 50%;
        overflow: hidden;
    }

    .four_item_msg {
        width: 987px;
        margin-left: 13px;
    }

    .four_item_msg > h2 {
        width: 100%;
        margin-top: 8px;
    }

    .four_item_msg > h2 > span:nth-child(1) {
        font-size: 17px;
        font-family: BentonSans;
        font-weight: 500;
        line-height: 20px;
        color: #000000;
    }

    .four_item_msg > h2 > span:nth-child(2) {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 19px;
        color: #000000;
    }

    .four_item_msg ul {
        margin-top: 20px;
    }

    .four_item_msg li {
        margin-right: 5px;
    }

    .four_item_msg > p {
        font-size: 14px;
        font-family: Montserrat;
        font-weight: 400;
        line-height: 23px;
        color: #707070;
        margin-top: 20px;
    }

    .user_five {
        width: 1200px;
        background: #FFFFFF;
        border-radius: 4px;
        margin-top: 20px;
        padding: 42px 46px 36px;
        box-sizing: border-box;
    }

    .user_five > h2, .user_six > h2, .user_seven > h2 {
        font-size: 26px;
        font-family: Roboto;
        font-weight: bold;
        line-height: 34px;
        color: #000000;
        padding-left: 22px;
    }

    .user_five .five_hint_one, .user_six .six_hint_one, .user_seven .seven_hint_one {
        font-size: 14px;
        font-family: Montserrat;
        font-weight: 400;
        line-height: 18px;
        color: #000000;
        padding-left: 22px;
        margin-top: 28px;
    }

    .user_five .five_hint_two, .user_six .six_hint_two, .user_seven .seven_hint_two {
        font-size: 20px;
        font-family: Montserrat;
        font-weight: bold;
        padding-left: 22px;
        line-height: 24px;
        color: #000000;
        margin-top: 9px;
    }

    .user_five_title, .six_title {
        width: 1110px;
        height: 47px;
        background: #FAFAFA;
        margin-top: 37px;
        line-height: 47px;
        padding-left: 24px;
        box-sizing: border-box;
        text-align: center;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #949494;
    }

    .five_date {
        width: 163px;
        text-align: left;
    }

    .five_name {
        width: 199px;
    }

    .five_url {
        width: 151px;
        text-align: center;
    }

    .five_lead {
        width: 150px;
        text-align: center;
    }

    .five_round {
        width: 219px;
    }

    .five_money {
        width: 200px;
        text-align: center;
    }

    .five_list, .six_list {
        padding-top: 29px;
        font-size: 14px;
        font-family: Montserrat;
        font-weight: 400;
        color: #000000;
    }

    .five_item, .six_item {
        height: 29px;
        line-height: 29px;
        padding-left: 24px;
        margin-bottom: 17px;
        font-size: 14px;
        font-family: Montserrat;
        font-weight: 400;
        color: #000000;
    }

    .five_name img, .five_round img, .six_name_two img, .six_round img {
        margin-right: 9px;
    }

    .user_six {
        width: 1200px;
        background: #FFFFFF;
        border-radius: 4px;
        margin-top: 20px;
        padding: 42px 46px 36px;
        box-sizing: border-box;
    }

    .six_date {
        width: 100px;
        text-align: left;
    }

    .six_name {
        width: 66px;
        text-align: center;
    }

    .six_url {
        width: 150px;
        text-align: center;
    }

    .six_name_two {
        width: 130px;
        box-sizing: border-box;
    }

    .six_lead {
        width: 110px;
        text-align: center;
    }

    .six_round {
        width: 228px;
    }

    .six_money {
        width: 120px;
        text-align: center;
    }

    .user_seven {
        width: 1200px;
        background: #FFFFFF;
        border-radius: 4px;
        margin-top: 20px;
        padding: 42px 46px 10px;
        box-sizing: border-box;
    }

    .seven_list {
        margin-top: 36px;
    }

    .seven_item {
        width: 503px;
        margin-right: 55px;
        margin-bottom: 31px;
    }

    .seven_item:nth-child(2n) {
        margin-right: 0;
    }

    .seven_item_left {
        width: 102px;
        height: 102px;
        background: #333333;
        border-radius: 7px;
        text-align: center;
        font-size: 63px;
        font-weight: bold;
        color: #FFFFFF;
        margin-right: 23px;
        overflow: hidden;
        border: 1px solid #000000;
    }

    .seven_item_right, .seven_item_right_two {
        width: 375px;
    }

    .seven_item_right h2 {
        font-size: 13px;
        font-family: Montserrat;
        font-weight: bold;
        line-height: 16px;
        color: #333333;
        margin-top: 15px;
    }

    .seven_item_right p {
        font-size: 14px;
        font-family: Montserrat;
        font-weight: 400;
        line-height: 18px;
        color: #000000;
        margin: 11px 0;
    }

    .seven_item_right div {
        font-size: 14px;
        font-family: Montserrat;
        font-weight: 400;
        line-height: 18px;
        color: #000000;
    }

    .seven_item_right_two h2 {
        font-size: 14px;
        font-family: Montserrat;
        font-weight: bold;
        line-height: 18px;
        color: #333333;
        margin-top: 14px;
        margin-bottom: 10px;
    }

    .seven_item_right_two p {
        margin-bottom: 8px;
        font-size: 14px;
        font-family: Montserrat;
        font-weight: 400;
        line-height: 18px;
        color: #000000;
    }

    .user_eight {
        width: 1200px;
        height: 373px;
        background: #FFFFFF;
        border-radius: 4px;
        margin-top: 20px;
        padding: 37px 0 43px 67px;
        box-sizing: border-box;
    }

    .user_eight li {
        width: 33%;
        margin-bottom: 37px;
    }

    .user_eight li > h2 {
        font-size: 14px;
        font-family: Montserrat;
        font-weight: 400;
        line-height: 18px;
        color: #000000;
        margin-bottom: 12px;
    }

    .user_eight li > p {
        font-size: 15px;
        font-family: Montserrat;
        font-weight: 500;
        line-height: 19px;
        color: #000000;
    }

    .user_eight > p {
        font-size: 14px;
        font-family: Montserrat;
        font-weight: 400;
        line-height: 18px;
        color: #000000;
    }

    .pop {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.22);
    }

    .pop_bg {
        width: 600px;
        height: 548px;
        background: #FFFFFF;
        border-radius: 8px;
        padding: 22px;
        box-sizing: border-box;
        margin: 10% auto;
    }

    .pop_close {
        text-align: right;
    }

    .pop_title {
        text-align: center;
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        line-height: 31px;
        color: #000000;
        margin-bottom: 68px;
    }

    .pop_bg_img {
        width: 559px;
        height: 65px;
        border-radius: 4px 4px 0px 0px;
        overflow: hidden;
        margin-bottom: 20px;
    }

    .pop_bg_img img {
        width: 100%;
        height: 100%;
    }

    .pop_hint_one {
        text-align: center;
        font-size: 13px;
        font-family: Montserrat;
        font-weight: 400;
        line-height: 16px;
        color: #000000;
        text-align: center;
        margin-bottom: 11px;
    }

    .pop_hint_two {
        font-size: 14px;
        font-family: Montserrat;
        font-weight: 400;
        line-height: 24px;
        color: #949494;
        text-align: center;
    }

    .pop_upload_btn {
        width: 140px;
        height: 50px;
        background: #FFFFFF;
        border: 1px solid #707070;
        border-radius: 131px;
        text-align: center;
        line-height: 48px;
        margin-top: 50px;
    }

    .upload-avatar {
        width: 140px;
        height: 50px;
        background: #FFFFFF;
        border: 1px solid #707070;
        border-radius: 131px;
        text-align: center;
        line-height: 48px;
        margin-top: 50px;
        display: block !important;
    }

    .upload-avatar .el-button {
        width: 99px !important;
        height: 32px !important;
        background: #FFFFFF !important;
        border: none !important;
        padding: 0 !important;
        border-radius: 131px !important;
        font-size: 14px !important;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
    }

    .pop_save_btn {
        width: 124px;
        height: 40px;
        background: linear-gradient(180deg, #000000 0%, #232321 100%);
        border-radius: 131px;
        text-align: center;
        line-height: 40px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #FFFFFF;
        margin-top: 50px;
    }
</style>
