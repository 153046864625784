<template>
    <!--弹窗-->
    <el-dialog :visible.sync="dialogFormVisible" class="dialog-wrap" width="700px">
        <h1 class="dialog-title" slot="title">{{ $t("bulletFrame.complaint") }}</h1>
        <div class="form">
            <div class="form-item">
                <div class="form-item-label"><span class="mi">*</span> {{$t("publish.phone")}}</div>
                <div style="display: inline-block;">
                    <el-select filterable style="width:200px !important;height:50px;" v-model="form.mobileCode">
                        <el-option :key="id" :label="'+'+item.code + '(' + item.countrysEnglish + ')'"
                                   :value="item.code" v-for="(item, id) in areaList.phoneCountriesCodeList">
                        </el-option>
                    </el-select>
                </div>
                <div class="ita-input-fronts" style="display: inline-block;width:350px;">
                    <!--<span v-if="form.mobileCode" style="position:absolute;margin-left:-40px;">+{{form.mobileCode}}</span>-->
                    <div class="v-line"></div>
                    <input :placeholder="$t('publish.input')" class="iif-input" style="width:200px;" type="text"
                           v-model="form.phone"/>
                </div>
            </div>
            <div class="form-item">
                <div class="form-item-label"><span class="mi">*</span> {{$t("publish.mailbox")}}</div>
                <div class="form-item-input">
                    <input :placeholder="$t('publish.input')" class="input-area" type="text" v-model="form.mail">
                </div>
            </div>
            <div class="form-item">
                <div class="form-item-label"><span class="mi"></span> WhatsApp</div>
                <div style="display: inline-block;">
                    <el-select filterable style="width:200px !important;height:50px;" v-model="form.whatsappCode">
                        <el-option :key="index" :label="'+'+item.code + '(' + item.countrysEnglish + ')'"
                                   :value="item.code" v-for="(item, index) in areaList.phoneCountriesCodeList">
                        </el-option>
                    </el-select>
                </div>
                <div class="ita-input-fronts" style="display: inline-block;width:350px;">
                    <!--<span v-if="form.whatsappCode" style="position:absolute;margin-left:-40px;">+{{form.whatsappCode}}</span>-->
                    <div class="v-line"></div>
                    <input :placeholder="$t('publish.input')" class="iif-input" style="width:200px;" type="text"
                           v-model="form.whatsApp"/>
                </div>
                <!-- <div class="form-item-input">
                  <input class="input-area" v-model="form.whatsApp" :placeholder="$t('publish.input')" type="text">
                </div> -->
            </div>
            <div class="form-item">
        <textarea :placeholder="$t('bulletFrame.theReasonForTheComplaint')" cols="30" id="" name="reason" rows="10"
                  v-model="form.reason"></textarea>
            </div>

        </div>
        <div class="dialog-footer" slot="footer">
            <div @click="submit" class="submit-button">{{$t("home.submit")}}</div>
        </div>
    </el-dialog>
</template>

<script>
    import {selectCountriesCodeInfo} from '@/api/userApi'

    export default {
        name: "InterviewDialog",
        data() {
            return {
                dialogFormVisible: false,
                form: {
                    phone: '',
                    mail: '',
                    whatsApp: '',
                    reason: '',
                    mobileCode: '86',
                    whatsappCode: '86',
                },
                areaList: [],
                formLabelWidth: '120px'
            };
        },
        components: {},
        props: {},
        mounted() {
            //获取地区编号
            this.getArea();
        },
        methods: {
            //获取地区
            getArea() {
                selectCountriesCodeInfo().then((res) => {
                    if (res.code == 200) {
                        this.areaList = res.data;
                    } else {
                        this.$message.error(res.msg)
                    }
                })
            },
            //打开弹窗
            openDialog() {
                this.dialogFormVisible = true;
            },
            //关闭弹窗
            closeDialog() {
                this.dialogFormVisible = false;
            },
            //提交按钮
            submit() {
                this.$parent.memberComplaint(this.form);//取消预约
                this.closeDialog();
            },

        },
    }
</script>

<style scoped>
    .ita-input-fronts {
        width: 300px;
        height: 50px;
        background: #fafafa;
        border-radius: 131px;
        margin-top: 22px;
        padding: 0 50px;
        line-height: 50px;
    }

    .iif-input {
        display: inline-block;

        background-color: transparent;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        width: 300px;
    }

    .dialog-wrap .dialog-title {
        text-align: center;
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        line-height: 31px;
        color: #000000;
    }

    .dialog-wrap .form {
        padding: 0 2px;
    }

    .form-item {
        margin-bottom: 31px;
    }

    .form-item-label {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 21px;
        color: #000000;
        margin-bottom: 22px;

    }

    .form-item-label .mi {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 19px;
        color: #707070;
    }

    .form-item-input {
        width: 536px;
        height: 50px;
        background: #FAFAFA;
        border-radius: 131px;
        overflow: hidden;
    }

    .form-item-input .phone-before {
        display: inline-block;
        width: 106px;
        height: 100%;
        background: #FAFAFA;
        padding-left: 26px;
        vertical-align: middle;
    }

    .form-item-input .phone-before span {
        display: inline-block;

        vertical-align: middle;
    }

    .form-item-input .phone-before input {
        display: inline-block;
        vertical-align: middle;
        width: 50px;
        height: 100%;
        background-color: transparent;
    }

    .form-item-input .v-line {
        display: inline-block;
        vertical-align: middle;
        width: 1px;
        height: 22px;
        background-color: #D6D6D6;
    }

    .form-item-input .phone-area {
        display: inline-block;
        vertical-align: middle;
        width: 350px;
        background-color: transparent;
        padding-left: 45px;
        padding-right: 45px;
        box-sizing: border-box;
    }

    .form-item-input .input-area {
        display: inline-block;
        vertical-align: middle;
        width: 100%;
        height: 100%;
        background-color: transparent;
        padding-left: 45px;
        padding-right: 45px;
        box-sizing: border-box;
    }

    .submit-button {
        width: 140px;
        height: 50px;
        background: #FFFFFF;
        border: 1px solid #707070;
        border-radius: 131px;
        text-align: center;
        line-height: 50px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        margin: 0 auto;
        cursor: pointer;
    }

    textarea {
        box-sizing: border-box;
        width: 100%;
        height: 149px;
        background: #FAFAFA;
        border-radius: 4px;
        padding: 17px 26px;
    }
</style>
